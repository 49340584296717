import React from 'react'
import styled from 'styled-components'
import { FaInstagram, FaFacebookF, FaRegEnvelopeOpen, FaTwitter } from 'react-icons/fa'
import { MdPhone } from 'react-icons/md'

import Section from './components/Section'

import logo from './images/DDLogo.png'
import image1 from './images/Top.png'
import image2 from './images/Middle.png'
import image3 from './images/Bottom.png'

const Wrapper = styled.div`
  font-family: sans-serif;
`

const Header = styled.div`
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Content = styled.div`
  background-color: white;
  min-height: 100px;
  align-items: center;
  font-size: 1.5em;
`

const Footer = styled.div`
  height: 3rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 2rem;
  background-color: blue;
`

const TextHeader = styled.span`
  font-weight: bold;
  font-size: 1.5rem;
`

const SocialIcon = styled.a`
  color: white;
  text-decoration: none;
`

const HeaderImage = styled.img`
  height: 2rem;
  text-align: center;
`

const IndexPage = () =>
  <Wrapper>

    <Header>
      <HeaderImage src={logo} />
      <TextHeader></TextHeader>
      <HeaderImage src={logo} />
    </Header>

    <Content>
      <Section image={image1} textCenter=
      "We believe in building networks that boost our client's advertising performance." />
      <Section image={image2} textCenter=
      "Distribute your online promotions or specials directly to anyone's smartphone with Bluetooth messaging." flip />
      <Section image={image3} textCenter=
      "Wanna Buy One?" />
    </Content>

    <Footer>
      <SocialIcon href='tel:9569291450' target='_blank'><MdPhone style={{ display: 'flex' }} /></SocialIcon>
      <SocialIcon href='tel:9569291450' target='_blank'><FaInstagram style={{ display: 'flex' }} /></SocialIcon>
      <SocialIcon href='tel:9569291450' target='_blank'><FaFacebookF style={{ display: 'flex' }} /></SocialIcon>
      <SocialIcon href='tel:9569291450' target='_blank'><FaTwitter style={{display: 'flex'}} /></SocialIcon>
      <SocialIcon href='tel:9569291450' target='_blank'><FaRegEnvelopeOpen style={{ display: 'flex' }} /></SocialIcon>
      
    </Footer>

  </Wrapper>

export default IndexPage